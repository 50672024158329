import React, { useEffect, useState } from "react";
import { Link } from "@quintype/components";
import { getCollection } from "../helper/api";
import SevenStories from "../collection-templates/seven-stories";

const NotFoundPage = () => {
  const [coll, setColl] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const res = await getCollection("top-stories", 0, 10, "");
      if (res?.length > 0) {
        setColl({ name: "Top Stories", items: res });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="container">
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          marginBottom: "15px",
          padding: "20px 0",
          background: "rgba(0, 0, 0, 0.04)"
        }}
      >
        <h1 style={{ fontSize: "30px", fontWeight: "bold", fontFamily: "var(--primary-font)", margin: "8px 0 2px" }}>
          ಪುಟ ಕಾಣಿಸುತ್ತಿಲ್ಲ :(
        </h1>
        <p
          style={{
            fontSize: "20px",
            textAlign: "center",
            margin: "6px 0",
            fontFamily: "var(--secondary-font)",
            fontWeight: "200"
          }}
        >
          ಕ್ಷಮಿಸಿ, ನೀವು ಹುಡುಕುತ್ತಿರುವ ಪುಟವು ಅಸ್ತಿತ್ವದಲ್ಲಿಲ್ಲ
        </p>
        <Link href="/" style={{ margin: "16px 0", display: "block" }}>
          <button
            style={{
              background: "#2d83b6",
              width: "180px",
              fontFamily: "var(--secondary-font)",
              padding: "12px 16px",
              borderRadius: " 4px",
              border: 0,
              color: "white"
            }}
          >
            ಮುಖಪುಟಕ್ಕೆ ಹಿಂತಿರುಗಿ
          </button>
        </Link>
      </div>
      <div style={{ width: "100%", height: 3, backgroundColor: "black", margin: "32px 0" }}></div>
      <div>
        <SevenStories collection={coll} page="not-found" />
      </div>
    </div>
  );
};

export { NotFoundPage };
