import React, { forwardRef, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { format, startOfDay, endOfDay } from "date-fns";
import get from "lodash/get";
// import throttle from "lodash/throttle";
import DatePicker from "react-datepicker";

import { setLoading } from "../../../components/store/actions";
import LatestNews from "../../story-templates/story-elements/latest-news";
import ListingPageTemplate from "../../collection-templates/listing-page-template";
import { getAdvancedSearchOfStories } from "../../../../api/utils";

import SearchBar from "../../molecules/search-bar";
import MobileSearchFilter from "../../molecules/mobile-search-filter";

import CheckBoxDropdown from "../../atoms/checkbox-dropdown";
import RadioButtonDropdown from "../../atoms/radio-button-dropdown";

import TaboolaAd from "../../ads/taboola-ad";
import { AdContainer } from "../../ads";
import SideBarContent from "../../molecules/sidebar-content";

import "./search.m.css";

const templates = [
  { value: "text", name: "Story" },
  { value: "video", name: "Video" },
  { value: "photo", name: "Photos" },
  { value: "podcast", name: "Podcast" }
];

const SearchPage = ({ data }) => {
  const dispatch = useDispatch();
  const cardsPerPage = 10;
  const searchParam = data?.query?.replace(/\?access_token=[^&]+/, "");
  const totalCount = data?.total;

  const defaultStories = searchParam ? data?.stories?.map((story) => story.story) : [];

  const [stories, setStories] = useState(defaultStories || []);
  const [searchedText, setSearchedText] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [sortOption, setSortOption] = useState("score");
  const [templateOption, setTemplateOption] = useState("");
  const [filterToggle, setToggleFilter] = useState(false);
  const [sections, setSections] = useState([]);
  const [sectionNames, setSectionNames] = useState("");
  const [checked, setChecked] = useState([]);
  const [total, setTotal] = useState(totalCount);
  const [showItems, setShowItems] = useState(false);
  const MemoizedSections = useSelector((state) => get(state, ["qt", "config", "sections"], []));
  const [loadNoStoryFound, setLoadNotStoryFound] = useState(false);
  const [initialPage, setInitialPage] = useState(0);
  const [showApplyBtn, setShowApplyBtn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const dateParam = { startDate: Number(startOfDay(startDate)), endDate: Number(endOfDay(endDate)) };
  const searchRef = useRef();
  const [renderTaboolaAd, setRenderTaboolaAd] = useState(true);

  // useEffect(() => {
  //   const getScroll = () => {
  //     if (typeof window !== "undefined") {
  //       if (window.scrollY > 500) setRenderTaboolaAd(true);
  //     }
  //   };
  //   window.addEventListener("scroll", throttle(getScroll, 300));
  //   return () => window.removeEventListener("scroll", getScroll);
  // }, []);

  useEffect(() => {
    if (searchParam) {
      setIsLoading(false);
    }
    if (showItems) {
      setInitialPage(1);
      setShowApplyBtn(true);
      /* eslint-disable prefer-const */
      let queryParams = {
        ...(searchParam && { q: searchParam }),
        ...(sectionNames && { "section-id": sectionNames }),
        ...(templateOption && { type: templateOption }),
        ...(dateParam.startDate && { "published-after": dateParam.startDate }),
        ...(dateParam.endDate && { "published-before": dateParam.endDate })
      };
      getAdvancedSearchOfStories({
        sort: sortOption,
        ...queryParams
      })
        .then((response) => {
          setIsLoading(false);
          setStories(response.items);
          setTotal(response.total);
          setLoadNotStoryFound(false);
        })
        .catch((error) => {
          console.log("error :>> ", error);
          setLoadNotStoryFound(true);
        });
    }
  }, [
    dateParam.startDate,
    dateParam.endDate,
    sortOption,
    sectionNames,
    searchParam,
    filterToggle,
    templateOption,
    showItems
  ]);

  useEffect(() => {
    const secs = MemoizedSections.slice(0, 50);
    setSections(secs);
  }, []);

  useEffect(() => {
    if (typeof window !== "undefined") {
      if (filterToggle) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "";
      }
    }
  }, [filterToggle]);

  const getMoreStories = async (page) => {
    const offset = (page - 1) * cardsPerPage;
    setInitialPage(page);
    const requiredParams = {
      ...(searchParam && { q: searchParam }),
      ...(sectionNames && { "section-id": sectionNames }),
      ...(templateOption && { type: templateOption }),
      ...(dateParam.startDate && { "published-after": dateParam.startDate }),
      ...(dateParam.endDate && { "published-before": dateParam.endDate }),
      limit: cardsPerPage,
      offset,
      sort: sortOption
    };

    dispatch(setLoading(true));
    searchRef.current?.scrollIntoView({
      behavior: "smooth"
    });

    await getAdvancedSearchOfStories(requiredParams)
      .then((res) => {
        setStories(res.items);
      })
      .finally(() => dispatch(setLoading(false)));
  };

  const filterToggleHandler = (toggleValue) => {
    setToggleFilter(toggleValue);
    setShowApplyBtn(false);
    setShowItems(false);
    setIsLoading(false);
  };

  const sortOptions = [
    { name: "ಇತ್ತೀಚೆಗೆ ಪ್ರಕಟಿತ", value: "latest-published" },
    { name: "ಸಂಬಂಧಿತ", value: "score" }
  ];

  const getSortOptionValue = (sortOption) => {
    setSortOption(sortOption);

    setShowItems(true);
  };
  const getTemplateOptionValue = (option) => {
    setTemplateOption(option);
    setShowItems(true);
  };

  const getStartDate = (date) => {
    setStartDate(date);
    setShowItems(true);
  };
  const getEndDate = (date) => {
    setEndDate(date);
    setShowItems(true);
  };

  const getSearchedText = (text) => {
    setSearchedText(text);
    setShowItems(true);
  };

  const getSectionNames = (names) => {
    setChecked(names);
    const updatedSectionNames = names.join(", ");
    setSectionNames(updatedSectionNames);
    setShowItems(true);
  };

  const filterDropdownOptions = ["ವಿಭಾಗ", "ಹೀಗೆ ವಿಂಗಡಿಸಿ", "ಮಾದರಿ", "ದಿನಾಂಕ"];

  const DateInput = forwardRef(({ onClick, date }, ref) => {
    return (
      <div onClick={onClick} ref={ref}>
        {date == null ? "DD/MM/YYYY" : format(date, "dd/MM/yyyy")}
      </div>
    );
  });

  return (
    <>
      <div className="hide-desktop">
        {filterToggle && (
          <MobileSearchFilter
            filterToggleHandler={filterToggleHandler}
            ddoptions={filterDropdownOptions}
            sectionOptions={sections}
            sortOptions={sortOptions}
            templateOptions={templates}
            getSortOptionValue={getSortOptionValue}
            getTemplateOptionValue={getTemplateOptionValue}
            getStartDate={getStartDate}
            getEndDate={getEndDate}
            startDate={startDate}
            endDate={endDate}
            sortValue={sortOption}
            templateValue={templateOption}
            getSelectedOptions={getSectionNames}
            checkedSections={checked}
            showApplyBtn={showApplyBtn}
          />
        )}
      </div>
      <div className="container" styleName="wrapper">
        <div>
          <SearchBar
            filterToggleHandler={filterToggleHandler}
            getSearchedText={getSearchedText}
            defaultValue={searchParam}
          />
          <div styleName="filter-section" className="hide-mobile">
            <div styleName="filter-wrapper">
              <RadioButtonDropdown
                title="ಹೀಗೆ ವಿಂಗಡಿಸಿ"
                options={sortOptions}
                getSelectedOptions={getSortOptionValue}
                option={sortOption}
              />
              <CheckBoxDropdown title="ವಿಭಾಗ" options={sections} getSelectedOptions={getSectionNames} />
              <RadioButtonDropdown title="ಮಾದರಿ" options={templates} getSelectedOptions={getTemplateOptionValue} />
            </div>
            <div styleName="range-date">
              <DatePicker
                selected={startDate}
                maxDate={new Date()}
                onChange={(date) => {
                  setStartDate(date);
                  setShowItems(true);
                }}
                customInput={<DateInput date={startDate} />}
              />
              <span> - </span>
              <DatePicker
                selected={endDate}
                maxDate={new Date()}
                onChange={(date) => {
                  setEndDate(date);
                  setShowItems(true);
                }}
                customInput={<DateInput date={endDate} />}
              />
            </div>
          </div>
          <div ref={searchRef} styleName="search-card"></div>
          {isLoading ? null : (
            <ListingPageTemplate
              loadStories={getMoreStories}
              storiesList={stories}
              perPage={cardsPerPage}
              totalCount={total}
              initialPage={initialPage}
            />
          )}
          {loadNoStoryFound && (
            <h1 styleName="text-info">
              {searchedText ||
              sectionNames ||
              templateOption ||
              dateParam.startDate ||
              dateParam.endDate ||
              (searchParam && !stories.length)
                ? "ಯಾವುದೇ ಫಲಿತಾಂಶಗಳು ಲಭ್ಯವಿಲ್ಲ"
                : ""}
            </h1>
          )}
        </div>
        <div>
          <div className="hide-mobile ad">
            <AdContainer AdClassName="standard-300x250" id="div-gpt-ad-1675836921322-0" desktop="PV_Desktop_HP_RT" />
          </div>
          <div className="video-element">
            <SideBarContent title="ಪ್ರಜಾವಾಣಿ ವಿಡಿಯೊ" speed={2500} type={"video"} />
          </div>

          <LatestNews />
          <div className="hide-mobile ad">
            <AdContainer AdClassName="standard-300x250" id="div-gpt-ad-1675837026472-0" desktop="PV_Desktop_HP_RM" />
          </div>
          {renderTaboolaAd ? (
            <div className="hide-mobile">
              <TaboolaAd
                container="taboola-right-rail-thumbnails"
                placement="Right Rail Thumbnails"
                mode="thumbnails-rr"
              />
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

SearchPage.propTypes = {
  data: PropTypes.object
};

export { SearchPage };
