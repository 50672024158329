import React, { useEffect } from "react";
import "./search-bar.m.css";
import { SvgIconHandler } from "../../atoms/svg-icon-hadler";
import { SearchBox } from "@quintype/components";

function SearchBar({ filterToggleHandler, defaultValue }) {
  const filterToggle = () => {
    filterToggleHandler(true);
  };

  const SearchIconTemplate = ({ children }) => (
    <div styleName="search-wrapper">
      {children}
      <button type="submit" styleName="search search-btn">
        <SvgIconHandler type="search" />
      </button>
      <button onClick={filterToggle} styleName="search" className="hide-desktop">
        <SvgIconHandler type="searchfilter" height="18px" width="18px" />
      </button>
    </div>
  );

  useEffect(() => {
    window.onload = function () {
      document.querySelector("input").focus();
    };
  }, []);

  return (
    <SearchBox
      placeholder="ಇಲ್ಲಿ ಹುಡುಕಿ…"
      inputId="searchForm"
      inputClassName="search__form-input"
      template={SearchIconTemplate}
      initValue={defaultValue}
    />
  );
}

export default SearchBar;
