/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import { useDispatch } from "react-redux";
// import throttle from "lodash/throttle";

import ListingPageTemplate from "../../collection-templates/listing-page-template";

import { setLoading } from "../../../components/store/actions";

import { getAdvancedSearchOfStories } from "../../../../api/utils";
import LatestNews from "../../story-templates/story-elements/latest-news";
import { AdContainer } from "../../ads";
import SideBarContent from "../../molecules/sidebar-content";
import TaboolaAd from "../../ads/taboola-ad";

import "./tag-page.m.css";

const TagPage = ({ data }) => {
  if (!data) return null;
  if (!data.stories) return null;

  const ref = useRef();
  const dispatch = useDispatch();
  const cardsPerPage = 10;
  const totalCount = data?.total;
  const [tagPageStories, setStories] = useState(data?.stories);
  const [renderTaboolaAd, setRenderTaboolaAd] = useState(true);

  // useEffect(() => {
  //   const getScroll = () => {
  //     if (typeof window !== "undefined") {
  //       if (window.scrollY > 500) setRenderTaboolaAd(true);
  //     }
  //   };
  //   window.addEventListener("scroll", throttle(getScroll, 300));
  //   return () => window.removeEventListener("scroll", getScroll);
  // }, []);

  const getMoreStories = async (page) => {
    const offset = (page - 1) * cardsPerPage;
    dispatch(setLoading(true));
    ref.current?.scrollIntoView({
      behavior: "smooth"
    });

    const requiredParams = {
      "tag-name": data?.tagName,
      limit: 10,
      offset,
      sort: "latest-published"
    };

    await getAdvancedSearchOfStories(requiredParams)
      .then((res) => setStories(res.items))
      .finally(() => dispatch(setLoading(false)));
  };

  const tagPageTitle = get(data, ["tags", "name"], "Tag Page");

  return (
    <div className="container" styleName="wrapper">
      <div>
        <div styleName="tag-card">
          <h1 ref={ref}>{tagPageTitle}</h1>
        </div>
        <ListingPageTemplate
          loadStories={getMoreStories}
          storiesList={tagPageStories}
          perPage={cardsPerPage}
          totalCount={totalCount}
        />
      </div>
      <div>
        <div className="hide-mobile ad">
          <AdContainer AdClassName="standard-300x250" id="div-gpt-ad-1675836921322-0" desktop="PV_Desktop_HP_RT" />
        </div>
        <div className="video-element">
          <SideBarContent title="ಪ್ರಜಾವಾಣಿ ವಿಡಿಯೊ" speed={2500} type={"video"} />
        </div>

        <LatestNews />
        <div className="hide-mobile ad">
          <AdContainer AdClassName="standard-300x250" id="div-gpt-ad-1675837026472-0" desktop="PV_Desktop_HP_RM" />
        </div>
        {renderTaboolaAd ? (
          <div className="hide-mobile">
            <TaboolaAd
              container="taboola-right-rail-thumbnails"
              placement="Right Rail Thumbnails"
              mode="thumbnails-rr"
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export { TagPage };

TagPage.propTypes = {
  data: PropTypes.object
};
