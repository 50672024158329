import React, { useEffect, useRef, useState } from "react";
import { object, array, number, string } from "prop-types";
import { format } from "date-fns";
import { kn } from "date-fns/locale";
import { Link } from "@quintype/components";
import CardImage from "../../atoms/cardimage";
import Headline from "../../atoms/headline";
import { useDispatch, useSelector } from "react-redux";
import { get } from "lodash";
import { putUserDetails } from "../../helper/utils";
import { setUserDetails } from "../../store/actions";
import "./bookmark-story-card.m.css";
import { SvgIconHandler } from "../../atoms/svg-icon-hadler";

const BookmarkStoryCard = ({ story, aspectRatio, imageType, headerType, headerLevel, addBookmark, removeBookmark }) => {
  const [isBookmarked, setIsBookmarked] = useState(false);
  const userdetails = useSelector((state) => get(state, ["user", "user"], {}));
  const [tip, setTip] = useState("");

  const publishedDate = format(new Date(story["published-at"]), "d MMMM yyyy, H:mm", { locale: kn }).replace(
    "ಫೆಬ್ರವರಿ",
    "ಫೆಬ್ರುವರಿ"
  );

  const isAlreadyBookmarked = userdetails?.metadata?.savedArticles?.some((article) => article.id === story.id);

  useEffect(() => {
    if (isAlreadyBookmarked) {
      setIsBookmarked(true);
    }
  }, [isAlreadyBookmarked]);

  const addBookmarkHandler = () => {
    addBookmark(story);
    setIsBookmarked(true);
    setTip("Added to Bookmarks");
  };
  const removeBookmarkHandler = () => {
    removeBookmark(story);

    setIsBookmarked(false);
    setTip("Removed from Bookmarks");
  };

  return (
    <div className="bookmark-card" styleName="bookmark-story-card">
      <Link href={`/${story.slug}`}>
        <div styleName="card-wrapper">
          <Headline text={story.headline} headerType={headerType} headerLevel={headerLevel} story={story} />
          <CardImage
            story={story}
            aspectRatio={aspectRatio}
            imageType={imageType}
            renderedWidth={720}
            widths={[720]}
            showVideoIcon={story["story-template"] === "video"}
            showCameraIcon={story["story-template"] === "photo"}
          />
        </div>
        <div styleName="date">{publishedDate} IST</div>
      </Link>
      <div styleName="timestamp">
        <div styleName="bookmark-icon-wrapper">
          {isBookmarked ? (
            <>
              <button styleName="bookmark-icon" onClick={removeBookmarkHandler}>
                <SvgIconHandler type="fill_bookmark_icon" width="14" height="18" viewBox="0 0 14 18" />
              </button>
              <span>{tip}</span>
            </>
          ) : (
            <>
              <button styleName="bookmark-icon" onClick={addBookmarkHandler}>
                <SvgIconHandler type="empty_bookmark" width="14" height="18" viewBox="0 0 15 18" />
              </button>
              <span>{tip}</span>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

BookmarkStoryCard.defaultProps = {
  aspectRatio: [16, 9],
  imageType: "image16x9",
  headerType: 2,
  headerLevel: 2
};

BookmarkStoryCard.propTypes = {
  story: object.isRequired,
  aspectRatio: array,
  imageType: string,
  headerType: number,
  headerLevel: number
};

export default BookmarkStoryCard;
