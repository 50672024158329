import React, { useState } from "react";
import { string, shape, object } from "prop-types";
import get from "lodash/get";

import { useDispatch } from "react-redux";

import StoryCard10 from "../../molecules/story-card10";
import Pagination from "../../molecules/pagination";
import { AdContainer } from "../../ads";

import { setLoading } from "../../../components/store/actions";
import { getCollection } from "../../helper/api";

import "./cartoon-page.m.css";
import useAdType from "../../helper/custom-hook/useAdType";

const CartoonPage = ({ data }) => {
  const dispatch = useDispatch();

  const adType = useAdType();
  const cartoonStories = data.collection?.items?.map((i) => i.story);
  const [stories, setStories] = useState(cartoonStories || []);

  const totalCount = get(data, ["collection", "total-count"], 0);
  const cardsPerPage = 24;

  if (!stories.length) {
    return <h1 styleName="text-info">No Stories Found</h1>;
  }

  const loadStories = async (page) => {
    const offset = (page - 1) * cardsPerPage;
    const slug = data.collection.slug;

    dispatch(setLoading(true));

    await getCollection(slug, offset, cardsPerPage)
      .then((i) => setStories(i))
      .finally(() => dispatch(setLoading(false)));
  };

  const pageTitle = data.collection.name;

  return (
    <div className="container" styleName="wrapper">
      <div styleName="title">
        <h1>{pageTitle}</h1>
      </div>
      {stories.length > 0 ? (
        <>
          <div styleName="collection-wrapper">
            {stories?.splice(0, 8).map((item, index) => (
              <StoryCard10 story={item} key={index} showVideoIcon={false} />
            ))}
          </div>
          <div styleName="ad-wrapper" className="hide-mobile">
            <AdContainer
              AdClassName="standard-970x250"
              id="div-gpt-ad-1675836977581-0"
              desktop="PV_Desktop_HP_Billboard_Mid01"
            />
          </div>
          <div styleName="collection-wrapper">
            {stories?.splice(0, 16).map((item, index) => (
              <StoryCard10 story={item} key={index} showVideoIcon={false} />
            ))}
          </div>

          <div styleName="ad-wrapper" className="hide-mobile">
            <AdContainer
              AdClassName="standard-970x250"
              id="div-gpt-ad-1683278304115-0"
              desktop="PV_Desktop_HP_Billboard_Mid02"
            />
          </div>

          <Pagination loadMoreStories={loadStories} totalItems={totalCount} perPage={cardsPerPage} />
        </>
      ) : (
        <h1 styleName="text-info">No stories found!</h1>
      )}
    </div>
  );
};

CartoonPage.propTypes = {
  pageType: string,
  data: shape({
    collection: object,
    section: string
  })
};

export { CartoonPage };
