import React from "react";
import { object, array, bool, number, string } from "prop-types";

import { format } from "date-fns";
import { kn } from "date-fns/locale";
import { Link } from "@quintype/components";
import get from "lodash/get";

import CardImage from "../../atoms/cardimage";
import Headline from "../../atoms/headline";

import VideoIcon from "../../atoms/icons/video-icon";

import "./listing-story-card.m.css";

const ListingStoryCard = ({
  story,
  aspectRatio,
  imageType,
  headerType,
  showAuthorName,
  id,
  showRedDot,
  headerLevel
}) => {
  if (!story) return null;

  const lastUpdatedDateAndTime = format(new Date(story["last-published-at"]), "d MMMM yyyy, H:mm", {
    locale: kn
  }).replace("ಫೆಬ್ರವರಿ", "ಫೆಬ್ರುವರಿ");
  const isClosed = get(story, ["metadata", "is-closed"], false);
  const headline = get(story, ["headline"], "");

  return (
    <div className="listing-story-card" styleName="listing-card">
      <Link href={`/${story.slug}`} styleName="card-wrapper">
        <div styleName="text-content">
          <div styleName="heading-content">
            <Headline
              text={headline}
              headerType={headerType}
              headerLevel={headerLevel}
              story={story}
              width={20}
              height={20}
              id={id}
              showRedDot={showRedDot}
              lineClamp={2}
            />
            {story["story-template"] === "live-blog" && !isClosed ? (
              <div>
                <div styleName="live-btn">
                  <div styleName="oval"></div>
                  <span>LIVE</span>
                </div>
              </div>
            ) : null}
          </div>
          <span styleName="summary" className="hide-mobile summary line-clamp-2">
            {story?.subheadline
              ? story?.subheadline
              : story.seo && story.seo["meta-description"]
              ? story.seo["meta-description"]
              : null}
          </span>
          <div styleName="timestamp">
            <div styleName="date">Last Updated {lastUpdatedDateAndTime} IST</div>
          </div>
        </div>
        <div styleName="video-card">
          <CardImage
            story={story}
            aspectRatio={aspectRatio}
            imageType={imageType}
            renderedWidth={720}
            widths={[700]}
            showAuthorName={showAuthorName}
            showStoryTypeIcon
            showAlternateImage
          />
          {story["story-template"] === "video" ? (
            <div styleName="video-icon">
              <VideoIcon width="40" height="40" />
            </div>
          ) : null}
        </div>
      </Link>
    </div>
  );
};

ListingStoryCard.defaultProps = {
  aspectRatio: [16, 9],
  imageType: "image16x9",
  headerType: 8,
  headerLevel: 1,
  showAuthorName: false,
  showSummary: true,
  isExternalStory: false,
  showRedDot: false
};

ListingStoryCard.propTypes = {
  story: object.isRequired,
  aspectRatio: array,
  imageType: string,
  headerType: number,
  headerLevel: number,
  showAuthorName: bool,
  showSummary: bool,
  id: string,
  isExternalStory: bool,
  pageType: string,
  showRedDot: bool
};

export default ListingStoryCard;
