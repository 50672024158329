import React, { useState } from "react";
import { SvgIconHandler } from "../svg-icon-hadler";
import "./radio-button-dropdown.m.css";

function RadioButtonDropdown({ title, options, getSelectedOptions, option }) {
  const [checked, setChecked] = useState(option);
  const [hoverState, setHoverState] = useState(false);

  const checkChangeHandler = (e) => {
    setChecked(e.target.value);
    getSelectedOptions(e.target.value);
    setHoverState(false);
  };

  return (
    <div styleName="wrapper">
      <div
        styleName={hoverState ? "dd ddhover" : "dd"}
        onMouseEnter={() => {
          setHoverState(true);
        }}
        onMouseLeave={() => {
          setHoverState(false);
        }}
      >
        <button styleName="dd-btn">
          {title}
          <SvgIconHandler type="downarrow" height="7px" width="12px" />
        </button>
        <div styleName="dropdown-section">
          {options.map((option, idx) => (
            <div key={idx} styleName={`dropdown-option ${checked === option.value ? "changeBg" : ""}`}>
              <label>
                <input
                  type="radio"
                  value={option.value}
                  checked={checked === option.value}
                  onChange={checkChangeHandler}
                />
                {option.name}
              </label>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default RadioButtonDropdown;
