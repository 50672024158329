import React from "react";
import { WithLazy } from "@quintype/components";

import HoroscopeGrid from "../../molecules/horoscope-grid";
import LogoDate from "../../atoms/logo-date";
import { AdContainer } from "../../ads";

import "./horoscope-page.m.css";

const HoroscopeListing = () => {
  return (
    <WithLazy>
      {() => (
        <div styleName="horoscope-wrapper">
          <h1 styleName="content-text title">ದಿನ ಭವಿಷ್ಯ</h1>
          <div styleName="content-text date">
            <LogoDate />
          </div>
          <div styleName="content">
            <HoroscopeGrid />
          </div>
          <div styleName="ad-wrapper" className="hide-mobile">
            <AdContainer
              AdClassName="standard-970x250"
              id="div-gpt-ad-1675836977581-0"
              desktop="PV_Desktop_HP_Billboard_Mid01"
            />
          </div>
        </div>
      )}
    </WithLazy>
  );
};

export { HoroscopeListing };
