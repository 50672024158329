import React, { forwardRef, useState } from "react";
import DatePicker from "react-datepicker";
import { SvgIconHandler } from "../../atoms/svg-icon-hadler";
import "./mobile-search-filter.m.css";
import { format } from "date-fns";

function MobileSearchFilter({
  filterToggleHandler,
  ddoptions,
  sortOptions,
  templateOptions,
  getSortOptionValue,
  getTemplateOptionValue,
  getStartDate,
  getEndDate,
  startDate,
  endDate,
  sortValue,
  templateValue,
  sectionOptions,
  getSelectedOptions,
  checkedSections,
  showApplyBtn
}) {
  const [showSection, setShowSection] = useState(false);
  const closeHandler = () => filterToggleHandler(false);

  const sortOptionChangeHandler = (e) => {
    getSortOptionValue(e.target.value);
  };
  const templateOptionChangeHandler = (e) => {
    getTemplateOptionValue(e.target.value);
  };

  const showSectionHandler = (idx) => {
    setShowSection(showSection === idx ? undefined : idx);
  };

  const checkChangeHandler = (e) => {
    let updatedList = [...new Set(checkedSections)];
    if (e.target.checked) {
      updatedList = [...checkedSections, e.target.value];
    } else {
      updatedList = checkedSections.filter((item) => item !== e.target.value);
    }
    getSelectedOptions(updatedList);
  };

  const changeBg = (item) => {
    return checkedSections?.find((option) => option === item) ? "changeBg" : "";
  };
  const DateInput = forwardRef(({ onClick, date }, ref) => {
    return (
      <div onClick={onClick} ref={ref}>
        {date == null ? "DD/MM/YYYY" : format(date, "dd/MM/yyyy")}
      </div>
    );
  });

  const currentDate = new Date().toLocaleDateString();

  return (
    <div styleName="wrapper">
      <div styleName="heading">
        <h2>ಫಿಲ್ಟರ್</h2>
        <div>
          {showApplyBtn && (
            <button styleName="apply-btn" onClick={closeHandler}>
              Apply
            </button>
          )}
          <button onClick={closeHandler} styleName="close-btn">
            <SvgIconHandler type="close" />
          </button>
        </div>
      </div>

      {ddoptions.map((option, idx) => (
        <>
          <div styleName="dropdown" key={idx}>
            <h4>{option}</h4>
            <button
              styleName={showSection === idx ? "dropdown-btn-rotate dropdown-btn" : "dropdown-btn"}
              onClick={() => showSectionHandler(idx)}
            >
              <SvgIconHandler type="circledownarrow" />
            </button>
          </div>
          {option === "ವಿಭಾಗ" && showSection === idx && (
            <div styleName="sort-expand-dropdown">
              {sectionOptions.map((option, idx) => {
                const option_id = option.id.toString();
                return (
                  <div styleName={`sort-option ${changeBg(option_id)}`}>
                    <label>
                      <input
                        key={idx}
                        type="checkbox"
                        value={option_id}
                        onChange={checkChangeHandler}
                        defaultChecked={checkedSections.includes(option_id)}
                      />
                      {option["display-name"]}
                    </label>
                  </div>
                );
              })}
            </div>
          )}
          {option === "ಹೀಗೆ ವಿಂಗಡಿಸಿ" && showSection === idx && (
            <div styleName="sort-expand-dropdown">
              {sortOptions.map((sortOption, idx) => (
                <div key={idx} styleName={`sort-option ${sortValue === sortOption.value ? "changeBg" : ""}`}>
                  <label>
                    <input
                      key={idx}
                      type="radio"
                      checked={sortValue === sortOption.value}
                      onChange={sortOptionChangeHandler}
                      value={sortOption.value}
                      label={sortOption.name}
                    />
                    {sortOption.name}
                  </label>
                </div>
              ))}
            </div>
          )}
          {option === "ಮಾದರಿ" && showSection === idx && (
            <div styleName="sort-expand-dropdown">
              {templateOptions.map((tempOption, idx) => (
                <div key={idx} styleName={`sort-option ${templateValue === tempOption.value ? "changeBg" : ""}`}>
                  <label>
                    <input
                      key={idx}
                      type="radio"
                      checked={templateValue === tempOption.value}
                      onChange={templateOptionChangeHandler}
                      value={tempOption.value}
                      label={tempOption.name}
                    />
                    {tempOption.name}
                  </label>
                </div>
              ))}
            </div>
          )}

          {option === "ದಿನಾಂಕ" && showSection === idx && (
            <div styleName="range-date">
              <DatePicker
                selected={startDate}
                maxDate={new Date()}
                onChange={(date) => getStartDate(date)}
                customInput={<DateInput date={startDate} />}
              />
              <span> - </span>
              <DatePicker
                selected={endDate}
                maxDate={new Date()}
                onChange={(date) => getEndDate(date)}
                customInput={<DateInput date={endDate} />}
              />
            </div>
          )}
        </>
      ))}
    </div>
  );
}

export default MobileSearchFilter;
