import React, { useEffect, useState } from "react";
import Paginate from "react-paginate";

import "./pagination.m.css";

const Pagination = ({ loadMoreStories, totalItems, perPage, initialPage = 1 }) => {
  const [currentPage, setCurrentPage] = useState(1);
  useEffect(() => {
    setCurrentPage(initialPage);
  }, [initialPage]);

  const totalNoItems = totalItems > 10000 ? 10000 : totalItems; // max limit 100000

  const pageCount = Math.ceil(totalNoItems / perPage);

  const handlePageClick = (page) => {
    setCurrentPage(page);
    loadMoreStories(page);
  };

  return (
    <div styleName="wrapper">
      {pageCount !== 1 ? (
        <Paginate
          className="wrapper"
          forcePage={currentPage - 1}
          pageCount={pageCount}
          marginPagesDisplayed={1}
          pageRangeDisplayed={2}
          onPageChange={({ selected }) => handlePageClick(selected + 1)}
          previousLabel="<"
          nextLabel=">"
          pageClassName="page"
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default Pagination;
