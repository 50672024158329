/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React from "react";

import ListingStoryCard from "../../molecules/listing-story-card";
import Pagination from "../../molecules/pagination";
import { AdContainer } from "../../ads";
import "./listing-page-template.m.css";

const ListingPageTemplate = ({ storiesList, loadStories, perPage, totalCount, initialPage }) => {
  if (storiesList && !storiesList.length) {
    return <h1 styleName="text-info">ಯಾವುದೇ ಫಲಿತಾಂಶಗಳು ಲಭ್ಯವಿಲ್ಲ</h1>;
  }

  return (
    <div>
      <div styleName="ad-wrapper" className="hide-desktop">
        <AdContainer AdClassName="standard-336x280" id="div-gpt-ad-1675837369206-0" mobile="PV_MWeb_HP_Top" />
      </div>
      {storiesList && storiesList.slice(0, 7).map((item, index) => <ListingStoryCard story={item} key={index} />)}
      <div styleName="ad-wrapper" className="hide-desktop">
        <AdContainer AdClassName="standard-336x280" id="div-gpt-ad-1675837402097-0" mobile="PV_MWeb_HP_Mid" />
      </div>
      {storiesList && storiesList.slice(7, 10).map((item, index) => <ListingStoryCard story={item} key={index} />)}
      <div styleName="ad-wrapper" className="hide-desktop">
        <AdContainer AdClassName="standard-336x280" id="div-gpt-ad-1675837430268-0" mobile="PV_MWeb_HP_Bottom" />
      </div>
      {totalCount ? (
        <Pagination loadMoreStories={loadStories} totalItems={totalCount} perPage={perPage} initialPage={initialPage} />
      ) : null}
    </div>
  );
};

export default ListingPageTemplate;
