/* eslint-disable max-len */
import React from "react";
import { string, shape, object } from "prop-types";
import { LazyCollection, LazyLoadImages } from "@quintype/components";

import { getCollectionTemplate } from "../../get-collection-template";

import { SubSectionPage } from "../sub-section-page";
import MobileCollection from "../../collection-templates/mobile-collection";

import TaboolaScript from "../../ads/taboola-ad/load-taboola-ad";
import TaboolaAd from "../../ads/taboola-ad";

import "./section-page.m.css";

const SectionPage = ({ data }) => {
  if (!data?.collection?.items?.length) return null;

  const { collection } = data;
  const stories = collection?.items?.filter((item) => item?.type === "story").slice(0, 6);

  if (stories?.length) return <SubSectionPage data={data} />;

  const pageTitle = collection?.name;

  const filterCollections = collection?.items?.filter((i) => i.items.length);

  const collection1 = { ...collection };
  collection1.items = [filterCollections && filterCollections[0]];

  const remainingCollection = { ...collection };
  remainingCollection.items = filterCollections?.slice(1);

  return (
    <>
      <div className="container" styleName="wrapper">
        <TaboolaScript type="category" />
        <div styleName="title">
          <h1>{pageTitle}</h1>
        </div>
        <LazyLoadImages>
          <div className="hide-mobile">
            <LazyCollection
              collection={collection1}
              collectionTemplates={getCollectionTemplate}
              lazyAfter={2}
              className="lazy-collection"
            />
          </div>
          <div className="hide-desktop">
            <MobileCollection collection={collection1.items[0]} />
          </div>
          <LazyCollection
            collection={remainingCollection}
            collectionTemplates={getCollectionTemplate}
            lazyAfter={2}
            className="lazy-collection"
          />
        </LazyLoadImages>

        <TaboolaAd
          container="taboola-below-category-thumbnail--pj"
          placement="Below Category Thumbnail- PJ"
          mode="alternating-thumbnails-a"
        />
      </div>
    </>
  );
};

SectionPage.propTypes = {
  pageType: string,
  data: shape({
    collection: object,
    section: string
  })
};

export { SectionPage };
