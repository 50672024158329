import React, { useRef, useState, useEffect } from "react";
import { object, shape } from "prop-types";
import { useDispatch } from "react-redux";
// import throttle from "lodash/throttle";
import get from "lodash/get";

import AuthorCard from "../../molecules/author-card";
import SideBarContent from "../../molecules/sidebar-content";

import { getAuthorStories } from "../../helper/api";
import { setLoading } from "../../../components/store/actions";
import LatestNews from "../../story-templates/story-elements/latest-news";
import ListingPageTemplate from "../../collection-templates/listing-page-template";

import { AdContainer } from "../../ads";
import TaboolaAd from "../../ads/taboola-ad";
import "./author.m.css";

export const AuthorPage = ({ data }) => {
  const dispatch = useDispatch();
  const authorCollection = data.authorCollection;
  const authorStories = authorCollection.items.map((i) => i.story);
  const [stories, setStories] = useState(authorStories || []);
  const [renderTaboolaAd, setRenderTaboolaAd] = useState(true);

  // useEffect(() => {
  //   const getScroll = () => {
  //     if (typeof window !== "undefined") {
  //       if (window.scrollY > 500) setRenderTaboolaAd(true);
  //     }
  //   };
  //   window.addEventListener("scroll", throttle(getScroll, 300));
  //   return () => window.removeEventListener("scroll", getScroll);
  // }, []);

  const authorRef = useRef();

  const totalCount = get(authorCollection, ["total-count"], 0);
  const cardsPerPage = 10;
  const authorId = data.author.id;

  const getMoreStories = async (page) => {
    const offset = (page - 1) * cardsPerPage;

    dispatch(setLoading(true));
    authorRef.current?.scrollIntoView({
      behavior: "smooth"
    });
    await getAuthorStories(authorId, offset, cardsPerPage)
      .then((i) => setStories(i))
      .finally(() => dispatch(setLoading(false)));
  };

  return (
    <div className="container" styleName="wrapper">
      <div ref={authorRef} styleName="author-stories">
        <AuthorCard author={data.author} />
        <ListingPageTemplate
          totalCount={totalCount}
          loadStories={getMoreStories}
          storiesList={stories}
          perPage={cardsPerPage}
        />
      </div>
      <div styleName="side-bar">
        <div className="hide-mobile ad">
          <AdContainer AdClassName="standard-300x250" id="div-gpt-ad-1675836921322-0" desktop="PV_Desktop_HP_RT" />
        </div>
        <div className="video-element">
          <SideBarContent title="ಪ್ರಜಾವಾಣಿ ವಿಡಿಯೊ" speed={2500} type={"video"} />
        </div>
        <LatestNews />
        <div className="hide-mobile ad">
          <AdContainer AdClassName="standard-300x250" id="sdiv-gpt-ad-1675837026472-0" desktop="PV_Desktop_HP_RM" />
        </div>
        {renderTaboolaAd ? (
          <div className="hide-mobile">
            <TaboolaAd
              container="taboola-right-rail-thumbnails"
              placement="Right Rail Thumbnails"
              mode="thumbnails-rr"
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

AuthorPage.propTypes = {
  data: shape({
    author: object,
    authorCollection: object
  })
};
