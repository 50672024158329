import React, { useEffect, useState } from "react";
import { SvgIconHandler } from "../svg-icon-hadler";
import "./checkbox-dropdown.m.css";

function CheckBoxDropdown({ title, options, getSelectedOptions }) {
  const [checked, setChecked] = useState([]);

  const checkChangeHandler = (option_id) => {
    const isChecked = checked.includes(option_id);
    let updatedList = [...checked];

    if (isChecked) {
      updatedList = updatedList.filter((item) => item !== option_id);
    } else {
      updatedList.push(option_id);
    }
    setChecked(updatedList);
    getSelectedOptions(updatedList);
  };

  const changeBg = (item) => {
    return checked?.find((option) => option === item) ? "changeBg" : "";
  };

  return (
    <div styleName="wrapper">
      <div styleName="dd">
        <button styleName={`dd-btn `}>
          {title}
          <SvgIconHandler type="downarrow" height="7px" width="12px" />
        </button>

        <div styleName="dropdown-section">
          {options.map((option, idx) => {
            const option_id = option.id.toString();
            return (
              <div
                key={idx}
                styleName={`dropdown-option ${changeBg(option_id)}`}
                onClick={() => checkChangeHandler(option_id)}
              >
                <input type="checkbox" value={option_id} checked={checked.includes(option_id)} readOnly />
                <span>{option["display-name"]}</span>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default CheckBoxDropdown;
